@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

:root {
	--app-height: 100%;
	--z-level-1: 100;
	--z-level-2: 120;
	--z-level-3: 130;
	--z-level-4: 140;
	--z-level-5: 150;
	--z-level-above-all: 999;
}

.Toastify__toast {
	padding: 0;
}

.Toastify__toast-icon {
	margin-top: 15px;
	margin-left: 8px;
	margin-inline-end: 20px;
	width: 36px;
	height: 100%;
	justify-content: center;
}

.Toastify__close-button {
	position: absolute;
	right: 5px;
	top: 5px;
}

.Toastify__toast-body {
	margin: 0;
	padding: 0;
}

.Toastify__toast-body > div:nth-child(2) {
	width: 100%;
	height: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
hr {
	margin: 0;
	padding: 0;
	border: 0;
	font-family: 'Roboto Flex', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

html,
body {
	line-height: 1;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	height: var(--app-height);
}
#root {
	position: relative;
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	margin: 0;
	padding: 0;
	border: 0;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.spinning {
	animation-name: spin;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@-ms-keyframes spin {
	from {
		-ms-transform: rotate(0deg);
	}

	to {
		-ms-transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}

	to {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

/*Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 30px white inset;
	box-shadow: 0 0 0 30px white inset;
	font-family: inherit;
	font-size: inherit;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}


/* Typography */

.caps {
	text-transform: uppercase;
}

/* Text - Single line */

.text-footer {
	font-family: "acumin-pro",sans-serif;
	font-weight: 400;
	font-size: 11px;
}


.text {
	font-family: "acumin-pro",sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 8px;
}

.text-l {
	font-family: "acumin-pro",sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
}

.text-xl {
	font-family: "acumin-pro",sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
}

/* Text - Paragraph */
.ptext-s {
	font-family: "acumin-pro",sans-serif;
	font-weight: 300;
	font-size: 8px;
}

/* not used */
.ptext {
	font-family: "acumin-pro",sans-serif;
	font-weight: 300;
	font-size: 12px;
}

.ptext-l {
	font-family: "acumin-pro",sans-serif;
	font-weight: 300;
	font-size: 16px;
}

.ptext-xl {
	font-family: "acumin-pro",sans-serif;
	font-weight: 300;
	font-size: 16px;
}

/* Headings */


.heading-xxs {
	font-family: "acumin-pro",sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
}

.heading-xs {
	font-family: "acumin-pro",sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
}

.heading-s {
	font-family: "acumin-pro",sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
}

.heading {
	font-family: "acumin-pro",sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 27px;
}

.heading-l {
	font-family: "acumin-pro",sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
}

.heading-xl {
	font-family: "acumin-pro",sans-serif;
	font-weight: 500;
	font-size: 32px;
	line-height: 43px;
	font-variation-settings: 'opsz' 14;
}


.heading-xxl {
	font-family: "acumin-pro",sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 64px;
	line-height: 64px;
}

/* Shadows */

.shadow-1 {
	filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
}

::-webkit-scrollbar {
	width: 14px;
	height: 14px;
}

::-webkit-scrollbar-thumb {
	height: 6px;
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 7px;
	box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
		inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
	display: none;
	width: 0;
	height: 0;
}

::-webkit-scrollbar-corner {
	background-color: transparent;
}

.disabled {
	pointer-events: none;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px #ECECEC inset !important;
}

.mobileStretch {
	@media screen and (max-width: 1236px) {
		width: calc(100vw - 60px ) !important;

	}
}
.mobileStretch2 {
	@media screen and (max-width: 1236px) {
		width: calc(100vw - 80px ) !important;

	}
}